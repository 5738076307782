'use strict';

const abSlider = require('core/components/slider');
const index = require('core/components/video/index');

var SiteConstants = require('constants/SiteConstants');
var mediumBreakpoint = SiteConstants.BreakpointSizes.md;
var largeBreakpoint = SiteConstants.BreakpointSizes.lg;

// Check custom attributes on slider element to get settings, falling back to defaults if not populated
abSlider.getSliderAttributes = function($slider) {
    var attributes = new Object();

    // Autoplay (defaults to false)
    attributes.autoplayDelay = parseInt($slider.attr('data-slider-autoplay'));
    attributes.autoplay = isNaN(attributes.autoplayDelay) ? false : true;
    attributes.autoplayTimeout = attributes.autoplay ? attributes.autoplayDelay : 5000;

    // Loop (defaults to true)
    attributes.loop = $slider.attr('data-slider-loop') === 'false' ? false : true;

    // Number of items to show (defaults to 1 at all breakpoints)
    attributes.itemsNumber = parseInt($slider.attr('data-slider-items'));
    attributes.items = isNaN(attributes.itemsNumber) ? 1 : attributes.itemsNumber;
    attributes.itemsNumberMedium = parseInt($slider.attr('data-slider-items-md'));
    attributes.itemsMedium = isNaN(attributes.itemsNumberMedium) ? 1 : attributes.itemsNumberMedium;
    attributes.itemsNumberLarge = parseInt($slider.attr('data-slider-items-lg'));
    attributes.itemsLarge = isNaN(attributes.itemsNumberLarge) ? 1 : attributes.itemsNumberLarge;

    // Gutter space between items (defaults to 0px)
    attributes.gutter = parseInt($slider.attr('data-slider-gutter')) || 0;
    attributes.gutterMedium = parseInt($slider.attr('data-slider-gutter-md')) || 0;
    attributes.gutterLarge = parseInt($slider.attr('data-slider-gutter-lg')) || 0;

    // Slide animation speed (defaults to 300ms)
    attributes.speed = parseInt($slider.attr('data-slider-speed')) || 300;

    // Slide animation (defaults to standard Carousel behavior)
    attributes.mode = $slider.attr('data-slider-mode') || 'carousel';
    attributes.animateIn = $slider.attr('data-slider-animatein') || 'no-animation';
    attributes.animateOut = $slider.attr('data-slider-animateout') || 'no-animation';

    // Thumbnail settings (defaults to false)
    // If a selector is set for [data-slider-thumbnail container], the rest of the settings populate automatically
    var $thumbnailSlider = $($slider.attr('data-slider-thumbnail-container'));
    attributes.thumbnailContainer = $slider.attr('data-slider-thumbnail-container') || false;
    attributes.navContainer = $thumbnailSlider.length ? $thumbnailSlider.find('.slider')[0] : false;
    attributes.navAsThumbnails = attributes.navContainer === false ? false : true;
    attributes.controlsContainer = $thumbnailSlider.length ? $thumbnailSlider.find('.slider-thumbnail-controls')[0] : false;

    // client custom
    attributes.autoWidth = $slider.attr('data-slider-auto-width') === 'true' ? true : false;

    return attributes;
};

// Check for videos and pause them when they're not in the active slide
abSlider.handleSliderVideos = function($sliderContainer, slider) {
    var $videos = $sliderContainer.find('.hero-media.video-container, .experience-commerce_assets-video>.video-container, .experience-commerce_assets-video360>.video-container');

    if ($videos.length) {
        slider.events.on('indexChanged', () => {
            $videos.each((_i, element) => {
                var $videoContainer = $(element);
                var videoID = $videoContainer.find('.video-player').attr('id');
                $('body').trigger('slider:init.sliderEvents', [$sliderContainer]);

                if (window & window.Players) {
                    var player = window.Players[videoID];

                    if (player) {
                        if (!$videoContainer.closest('.tns-slide-active').length) {
                            player.pause();
                        } else if (player.config.autoplay) {
                            player.play();
                        }
                    }
                }
            });
        });
    }
};

// Build parameter object to create a slider
abSlider.getSliderParams = function($slider, slideItems) {
    var attributes = abSlider.getSliderAttributes($slider);
    var params = {
        container: $slider.find('.slider')[0],
        loop: attributes.loop,
        items: attributes.items,
        autoplay: attributes.autoplay,
        autoplayTimeout: attributes.autoplayTimeout,
        autoplayHoverPause: true,
        mode: attributes.mode,
        gutter: attributes.gutter,
        speed: attributes.speed,
        navContainer: attributes.navContainer,
        navAsThumbnails: attributes.navAsThumbnails,
        controlsContainer: attributes.controlsContainer,
        preventScrollOnTouch: 'auto', // prevents mobile errors in chrome,
        autoWidth: attributes.autoWidth,
        responsive: {
            [mediumBreakpoint]: {
                items: attributes.itemsMedium,
                gutter: attributes.gutterMedium,
            },
            [largeBreakpoint]: {
                items: attributes.itemsLarge,
                gutter: attributes.gutterLarge,
            }
        }
    }

    if (slideItems) {
        params.slideItems = slideItems;
    }

    if (params.mode === 'gallery') {
        params.animateIn = attributes.animateIn;
        params.animateOut = attributes.animateOut;
    }

    return params;
};

// Enables zoomed images using jquery-zoom
abSlider.enableImageZoom = function($sliderContainer) {
    if ($sliderContainer.closest('.primary-images').length
        && !$sliderContainer.hasClass('slider-thumbnails')
        && !window.isMobile()) {
        var $slides = $sliderContainer.find('.slide');
        $slides.each((index, slide) => {
            var $slide = $(slide);

            if (!$slide.hasClass('no-zoom')) {
                var zoomImageUrl = $slide.data('zoom-image') || $slide.find('img').attr('src');
                $slide.zoom({
                    url: zoomImageUrl,
                    magnify: $slide.attr('data-zoom-image-magnification'),
                    touch: false
                });
            }
        });
    }
}

abSlider.setTheme = function($sliderContainer) {
    var $currentSlide = $sliderContainer.find('.tns-slide-active');
    var currentTheme = $currentSlide.find('.rolex-v7-slider-item').attr('data-theme');

    $sliderContainer.attr('data-theme', currentTheme);
}

// Dynamically adjusts slider controls colors according to slide's theme.
abSlider.handleThemeChange = function($sliderContainer, slider) {
    abSlider.setTheme($sliderContainer);

    slider.events.on('indexChanged', () => {
        abSlider.setTheme($sliderContainer);
    });
}

module.exports = abSlider;
