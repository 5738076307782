var exports = $.extend({}, require('core/search/search'));

var setRefinementCollapseStates = status => {

    if (status === 'open') {
        $('.refinement').addClass('active');
    }
    else if (status === 'closed') {
        $('.refinement').removeClass('active');
        $('.refinement').find('button.title').attr('aria-expanded','false');
    }
};

var toggleRefinementDrawer = status => {
    var $refinementBar = $('.refinement-bar');

    if (status === 'open') {
        var $header = $('header');
        var bannerHeight = $header.find('.header-banner').length ? $header.find('.header-banner').outerHeight() : 0;
        var navHeight = $header.find('.header-nav').length ? $header.find('.header-nav').outerHeight() : 0;
        var headerNavHeight = bannerHeight + navHeight;
        var scrollTopHeight = $header.offset().top;
        $('html').scrollTop(scrollTopHeight);
        // Following two lines for MS Edge to work
        document.body.scrollTop = scrollTopHeight;
        document.documentElement.scrollTop = scrollTopHeight;
        $('html').addClass('lock-scroll');
        $refinementBar.addClass('in').siblings().attr('aria-hidden', true);

        if (!isMobile) {
            $refinementBar.css('top', headerNavHeight);
        }

        $refinementBar.closest('.row').siblings().attr('aria-hidden', true);
        $refinementBar.closest('.tab-pane.active').siblings().attr('aria-hidden', true);
        $refinementBar.closest('.container.search-results').siblings().attr('aria-hidden', true);
        $refinementBar.find('.close').focus();
    } else {
        $('html').removeClass('lock-scroll');
        $refinementBar.removeClass('in').css('top', '').siblings().attr('aria-hidden', false);
        $refinementBar.closest('.row').siblings().attr('aria-hidden', false);
        $refinementBar.closest('.tab-pane.active').siblings().attr('aria-hidden', false);
        $refinementBar.closest('.container.search-results').siblings().attr('aria-hidden', false);
        $('.btn.filter-results').focus();
        $('#collapse-filters-wrapper').modal('hide');
    }
};

var toggleFilters = (e) => {
    e.preventDefault();
    var toggleDirection = 'open';
    if ($('.refinement-bar .refinement').hasClass('active')) {
        toggleDirection = 'closed';
    }
    setRefinementCollapseStates(toggleDirection);
};

var updateCardTitle = () => {
    var isMobile = window.isMobile();
    var selectedFilterItem = $('.values .selected');

    if (selectedFilterItem) {
        var closestRefinementParent = selectedFilterItem.closest('.refinement');
        closestRefinementParent.each(function () {
            $(this).addClass('active');
            var buttonTitle = $(this).find('button.title');
            if (isMobile) {
                var buttonTitleText = buttonTitle.find('span');
            } else {
                var buttonTitleText = buttonTitle.find('.card-title-text');
            }

            buttonTitle.attr('aria-expanded', 'true');
            buttonTitleText.css('font-weight','600');
        });
    }
};

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, selector) {
    var $updates = $results.find(selector);
    $(selector).empty().html($updates.html());
}

/**
 * This function retrieves another page of content to display in the content search grid
 * @param {JQuery} $element - the jquery element that has the click event attached
 * @param {JQuery} $target - the jquery element that will receive the response
 * @return {undefined}
 */
function getContent($element, $target) {
    var showMoreUrl = $element.data('url');
    $.spinner().start();
    $.ajax({
        url: showMoreUrl,
        method: 'GET',
        success: function (response) {
            $target.append(response);
        },
        complete: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Update sort option URLs from Ajax response
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function updateSortOptions(response, promoTileCount) {
    var $tempDom = $('<div>').append($(response));
    var sortOptions = $tempDom.find('.grid-footer').data('sort-options').options;
    var pageSize = Number($tempDom.find('.grid-footer').data('page-size'));

    sortOptions.forEach(option => {
        var pageUrl = new URL(window.location);
        var urlPageSize = pageUrl.searchParams.get('sz') !== null ? Number(pageUrl.searchParams.get('sz')) : null;
        var optionUrl = new URL(option.url);
        var optionPageSize = Number(optionUrl.searchParams.get('sz'));

        // If 'sz' is already defined in URL, but promo tiles aren't already accounted for, decrement sz param
        if (!isNaN(urlPageSize) && urlPageSize !== optionPageSize - pageSize) {
            var updatedPageSize = optionPageSize - promoTileCount;
            optionUrl.searchParams.set('sz', updatedPageSize);
            option.url = optionUrl.toString();
        }

        $('option.' + option.id).val(option.url);
    });
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function handleRefinements($results, selector) {
    $('.refinement.active').each(function () {
        $(this).removeClass('active');
        var activeDiv = $results.find('.' + $(this)[0].className.replace(/ /g, '.'));
        activeDiv.addClass('active');
        var buttonTitle = activeDiv.find('button.title');
        var buttonTitleText = buttonTitle.find('h4');

        buttonTitle.attr('aria-expanded', 'true');
        buttonTitleText.css('font-weight','600');
    });
    updateDom($results, selector);
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function parseResults(response) {
    var $results = $(response);
    var isMobile = window.isMobile();

    if (isMobile) {
        var $refinements = $results.find('.refinements[data-refinement-style-mobile]');
        var refinementStyle = $refinements.data('refinement-style-mobile');
        var selector = `[data-refinement-style-mobile="${refinementStyle}"]`;
    } else {
        var $refinements = $results.find('.refinements[data-refinement-style-desktop]');
        var refinementStyle = $refinements.data('refinement-style-desktop');
        var selector = `[data-refinement-style-desktop="${refinementStyle}"]`;
    };

    // Update browser history with Search-Show specific route
    var historyUrl = $results.find('.history-url').data('history-url');
    if (historyUrl) {
        history.replaceState(undefined, '', historyUrl);
    }

    // client custom for Primary Refinement filter
    const $newPrimaryRefinement = $results.find('.primary-refinement-container');
    const $oldPrimaryRefinement = $('.primary-refinement-container');
    if ($newPrimaryRefinement.length && $oldPrimaryRefinement.length) {
        $oldPrimaryRefinement.empty().append($newPrimaryRefinement.html());
        const slider = require('../components/slider');
        slider.initializeSliders($oldPrimaryRefinement);
    }
    // end client custom

    var $filterBar = $results.find('.filter-bar');
    var filterBarStyle = !isMobile ? $filterBar.attr('data-filter-bar-style-desktop') : $filterBar.attr('data-filter-bar-style-mobile');
    var filterBar = !isMobile ? '[data-filter-bar-style-desktop="' + filterBarStyle + '"]' : '[data-filter-bar-style-mobile="' + filterBarStyle + '"]';

    var verticalMobile = isMobile && selector === '[data-refinement-style-mobile="vertical"]';
    var verticalDesktop = !isMobile && selector === '[data-refinement-style-desktop="vertical"]';
    var horizontalOneDropdownDesktop = !isMobile && selector === '[data-refinement-style-desktop="horizontal-one-dropdown"]';
    var horizontalOneDropdownDesktopActive = $('.collapse-filters-wrapper').hasClass('active');
    var horizontalOneDropdownDesktopOpen = false;

    if (horizontalOneDropdownDesktop && horizontalOneDropdownDesktopActive) {
        horizontalOneDropdownDesktopOpen = true;
    }

    if (verticalMobile || verticalDesktop) {
        var specialHandlers = {
            selector : handleRefinements
        };

        Object.keys(specialHandlers).forEach(function (selector) {
            specialHandlers[selector]($results, selector);
        });
    };


    if (!isMobile) {
        var sortBy = '.sort-by';
        var refinements = '.desktop-search-refinements';
    } else {
        var sortBy = '';
        var refinements = '.mobile-search-refinements';
    };

    // Update DOM elements that do not require special handling
    [
        '.grid-header',
        '.header-bar',
        '.header.page-title',
        '.product-grid',
        '.show-more',
        refinements,
        sortBy,
        filterBar
    ].forEach((selector) => {
        updateDom($results, selector);
    });

    if (horizontalOneDropdownDesktopOpen === true) {
        $('.collapse-filters-wrapper').addClass('active').css('display', 'block');
    }

    $('body').trigger('ajax:load.ajaxEvents', [$('.search-results')]);

    initPriceRange();
    updateCardTitle();
    if(!isMobile) {
        scrollTop();
    }
    exports.refinementStickyBar();
}

exports.refinementStickyBar = function() {
    var showStickyRefinementData = document.getElementById('horizontal-filter');
    if (showStickyRefinementData) {
        var showStickyRefinementBarCheck = showStickyRefinementData.getAttribute('data-sticky-refinement-bar');
        var topHeader = document.getElementById('top-header'); // includes promobar and nav
        var headerNavHeight = document.querySelector('.header-nav').offsetHeight;
        var mainContent = document.getElementById('maincontent');
        var isMobile = window.isMobile();

        //if header is fixed, use just the nav bar to measure top of page, else use the entire header (may contain promo bar)
        if (topHeader.classList.contains('fixed-header-enhanced')) {
            var topHeaderHeight = headerNavHeight;
            var heightCalculation = (document.querySelector('.search-results').offsetTop - headerNavHeight);
        } else {
            var topHeaderHeight = 0;
            var heightCalculation = document.querySelector('.search-results').offsetTop;
        }

        if (showStickyRefinementData && !isMobile && showStickyRefinementBarCheck == 'true') {
            // Note: This is intentionally not using utilities/scroll.js so that it can fix immediately vs. being debounced. Should be replaced with CSS position:sticky once IE11 support is dropped.
            window.addEventListener('scroll', function() {
                //main sticky horiziontal dropdown filter functionality - only show when scrolled past in-page refinement button section
                if (window.pageYOffset > heightCalculation && mainContent.scrollHeight > (window.innerHeight + showStickyRefinementData.offsetHeight)) {
                    showStickyRefinementData.classList.add('sticky-filters');
                    showStickyRefinementData.setAttribute('style', 'top:' + topHeaderHeight + 'px;');
                    mainContent.setAttribute('style', 'padding-top:' + showStickyRefinementData.offsetHeight + 'px;');
                } else if (showStickyRefinementData.classList.contains('sticky-filters') && window.pageYOffset < heightCalculation) {
                    showStickyRefinementData.classList.remove('sticky-filters');
                    mainContent.removeAttribute('style');
                }
            });
        }
    }
}

function initPriceRange() {
    $('.price-range input').on('change', function () {
        let range = $(this).parents('.price-range');
        let inputMin = range.find('.price-range_min').val();
        let inputMax = range.find('.price-range_max').val();
        let minVal = parseFloat(inputMin);
        let maxVal = parseFloat(inputMax);
        let applyButton = range.find('.price-range_apply');
        let href = applyButton.attr('data-hrefBase');
        let newPrice = new URL(href);

        if (maxVal) {
            maxVal = Math.max(0, maxVal);
        }

        if (minVal) {
            minVal = Math.max(0, minVal);
        }

        applyButton.removeData('href');
        range.find('.price-range_error').addClass('d-none');

        if (!inputMin && !inputMax) {
            applyButton.addClass('no-values');
            return;
        } else {
            applyButton.removeClass('no-values');
        }

        if ((!minVal && inputMin) || ( !maxVal && inputMax)) {
            range.find('.invalid-input').removeClass('d-none');
            return;
        }

        if (minVal && maxVal && minVal >= maxVal) {
            range.find('.invalid-order').removeClass('d-none');
            return;
        }
        
        if (minVal) {
            newPrice.searchParams.set('pmin', minVal);
        } else {
            newPrice.searchParams.delete('pmin');
        }

        if (maxVal) {
            newPrice.searchParams.set('pmax', maxVal);
        } else {
            newPrice.searchParams.delete('pmax');
        }
        applyButton.data('href', newPrice.pathname + newPrice.search);        
    })
    $('.price-range_apply').on('click', function (e) {
        if ($(this).hasClass('no-values')) {
            $('.price-range .no-input').removeClass('d-none');
        }
    });
}

function scrollTop() {
    var offset = $('.search-results').offset().top;
    offset = offset - 120; //header
    offset = Math.max(offset, 0);
    $('html, body').scrollTop(offset);
}

exports.initialize = () => $(document).ready(function () {
    var isMobile = window.isMobile();
    var collapseMobile = $('[data-collapse-mobile]').attr('data-collapse-mobile') === 'true' ? true : false;
    var collapseDesktop = $('[data-collapse-desktop]').attr('data-collapse-desktop') === 'true' ? true : false;
    if (isMobile && collapseMobile) {
        setRefinementCollapseStates('close');
    } else if (isMobile && !collapseMobile) {
        setRefinementCollapseStates('open');
    } else if (!isMobile && collapseDesktop) {
        setRefinementCollapseStates('close');
    } else if (!isMobile && !collapseDesktop) {
        setRefinementCollapseStates('open');
    }
    if ($('#articles-tab').hasClass('active') && $('#content-search-results').html() === '') {
        getContent($('.content-search'), $('#content-search-results'));
    }

    var refinementCategoryOpen = localStorage.getItem('refinement-category');
    if (refinementCategoryOpen) {
        $('.refinement-category').addClass('active');
    }
    initPriceRange();

    updateCardTitle();
});

exports.closeRefinements = function () {
    $('html').on('click', '.js-close, .close, .js-close-results', (e) => toggleRefinementDrawer('close'));

    $('html').on('click', '.modal-backdop', function() {
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open').removeAttr('style');
        $('.collapse-filters-wrapper').hide().removeAttr('style').removeClass('show');
    });
}

exports.sort = function () {
    var $xhr;
    // Handle sort order menu selection
    $('.container').on('change', '[name=sort-order]', function (e) {
        e.preventDefault();
        e.stopPropagation();

        var $this = $(this);
        var thisValue = this.value;
        $this.trigger('search:sort', thisValue);
        var sortOrder = $this.val().split('srule')[1];
        var url = window.location.toString().split('&srule')[0];
        if (url.includes('srule')) {
            url = window.location.toString().split('?srule')[0];
        }

        var split = url.indexOf('?') !== -1 ? '&' : '?';
        var newUrl = url + split + 'srule' + sortOrder;

        var newSelection = this.selectedOptions[0];
        $('[name="sort-order"]').find('option[selected="selected"], option:selected').prop('selected',false).removeAttr('selected');
        $(newSelection).prop('selected', true).attr('selected', true);

        // Cancel previous request
        $xhr && $xhr.abort && $xhr.abort();

        $xhr = $.ajax({
            url: thisValue,
            data: {
                selectedUrl: thisValue,
                isSortUpdate: true
            },
            method: 'GET',
            timeout: 10000,
            beforeSend: function () {
                $.spinner().start();
            },
            success: function (response) {
                var $productGridElement = $(response).find('.product-grid'); // if response is coming from page designer
                var updatedGridHtml = $productGridElement.length > 0 ? $productGridElement.children() : response;
                $('.product-grid').empty().html(updatedGridHtml);
                history.replaceState(undefined, '', newUrl);
                $('body').trigger('search:sort--success');
                $('body').trigger('ajax:load.ajaxEvents', [$('.product-grid')]);
                $this.data('sortValueOption', newSelection);
            },
            complete: function () {
                $.spinner().stop();
            }
        });
    });
}

exports.showMore = function () {
    // Show more products
    $('.container').on('click', '.show-more button', function (e) {
        e.stopPropagation();
        e.preventDefault();
        var showMoreUrl = $(this).data('url');
        var $showMoreButton = $(e.target);
        $.spinner().start();
        $(this).trigger('search:showMore', e);

        $.ajax({
            url: showMoreUrl,
            data: { selectedUrl: showMoreUrl },
            method: 'GET',
            success: function (response) {
                var promoTileCount = $showMoreButton.closest('.product-grid').find('[class*="experience-promoTile"]').length;
                $('.grid-footer').replaceWith(response);
                updateSortOptions(response, promoTileCount);
                $('body').trigger('search:showMore--success');
                $("body").trigger("search:updateHistory");
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
}

exports.applyFilter = function () {
    var $xhr;
    // Handle refinement value selection and reset click
    $('.container').on(
        'click',
        '.refinements li button, .js-reset, .filter-bar button.reset, .filter-value button, .swatch-filter button, .primary-refinement-slider button, .price-range_apply',
        function (event) {
            var category = $(event.currentTarget).closest('.refinement');

            if (category && category.hasClass('refinement-category')) {
                localStorage.setItem('refinement-category', true);
                return;
            }
            //find new attr on Sort if selected, if not use the default
            var selectedSort = $('select[name="sort-order"]').find('option[selected="selected"]');
            if (selectedSort.data('id') != null) {
                var sortingRule = selectedSort.data('id');
            } else {
                var sortingRule = $('select[name="sort-order"] option:selected').data('id');
            }
            //create a new url with the correct preferences
            if ($(this).data('href').includes('srule')) {
                var refinementUrl = $(this).data('href').replace(/(srule=).*?(&|$)/,'$1' + sortingRule + '$2');
            } else if (!$(this).data('href').includes('srule') && sortingRule != null) {
                var split = $(this).data('href').indexOf('?') !== -1 ? '&' : '?';
                var refinementUrl = $(this).data('href') + split + 'srule=' + sortingRule;
            } else {
                var refinementUrl = $(this).data('href');
            }

            event.preventDefault();
            event.stopPropagation();
            $(this).trigger('search:filter', event);

            // Cancel previous request
            $xhr && $xhr.abort && $xhr.abort();

            $xhr = $.ajax({
                url: decodeURIComponent(refinementUrl),
                data: {
                    page: $('.grid-footer').data('page-number')
                },
                method: 'GET',
                timeout: 10000,
                beforeSend: function () {
                    $.spinner().start();
                },
                success: function (response) {
                    module.exports.methods.parseResults(response);
                    $('body').trigger('search:filter--success');
                },
                complete: function () {
                    $.spinner().stop();
                }
            });
        });
};

exports.updateHistory = function() {
    $("body").on("search:updateHistory", (function() {
        history.replaceState(null, document.title, $(".permalink").val())
    }
    ))
}

exports.filter = () => $('html').on('click', 'button.filter-results', () => toggleRefinementDrawer('open'));
exports.toggle = () => $('html').on('click', '.js-toggle-filters', (e) => toggleFilters(e));
exports.reset = () => $('html').on('click', '.js-reset', (e) => $('.refinements li button').trigger('click'));
exports.resize = () => {
    var windowWidth = $(window).width();

    $(window).resize(() => {
        // iOS fix: make sure window actually resized before triggering resize functions
        if (windowWidth !== $(window).width()) {
            windowWidth = $(window).width();
            toggleRefinementDrawer('close');
            exports.oneDropdownSlideToggle();
        }
    });
};

exports.methods = {
    parseResults: parseResults
}

module.exports = exports;
